import DataFilterHelper from './DataFilterHelper';
import {
  FILTER_TYPE_CATEGORIES,
  FILTER_TYPE_DATES,
  FILTER_TYPE_DESTINATIONS,
  FILTER_TYPE_SORT,
} from '@/helpers/data-filter/DataFilterTypes';

class ArtFairDataFilterHelper extends DataFilterHelper {
  defaultValues = {
    [FILTER_TYPE_DESTINATIONS]: [],
    [FILTER_TYPE_CATEGORIES]: [],
    [FILTER_TYPE_DATES]: [],
    [FILTER_TYPE_SORT]: null,
  };
  validValuesTypes = {
    array: [FILTER_TYPE_DESTINATIONS, FILTER_TYPE_CATEGORIES, FILTER_TYPE_DATES],
  };

  /**
   * @param {Object} groupsWithItems
   * @param {FilterGroupItem[]|null} groupsWithItems.destinations
   * @param {FilterGroupItem[]|null} groupsWithItems.categories
   * @param {FilterGroupItem[]|null} groupsWithItems.dates
   * @returns {FilterGroup[]}
   */
  prepareFilterGroups(groupsWithItems) {
    /**
     * @type {FilterGroup[]}
     */
    const groups = [
      this.addDefaultGroupItems({
        group: groupsWithItems.destinations,
        itemsType: FILTER_TYPE_DESTINATIONS,
      }),
      this.addDefaultGroupItems({
        group: groupsWithItems.categories,
        itemsType: FILTER_TYPE_CATEGORIES,
      }),
      this.addDateGroupItems({
        group: groupsWithItems.dates,
        itemsType: FILTER_TYPE_DATES,
        title: 'Dates',
      }),
      this.addRadioGroupItems({
        itemsType: FILTER_TYPE_SORT,
        items: [
          {
            title: 'Sort alphabetically',
            value: { column: 'title' },
          },
        ],
      }),
    ];

    return groups.filter((v) => v);
  }

  prepareFilterValuesToQueryVariables(groups = {}) {
    const transformed = super.prepareFilterValuesToQueryVariables(groups);

    return {
      filters: {
        destinationSlug: transformed[FILTER_TYPE_DESTINATIONS],
        category: transformed[FILTER_TYPE_CATEGORIES],
        date: transformed[FILTER_TYPE_DATES],
      },
      sort: transformed[FILTER_TYPE_SORT],
    };
  }
}

export default ArtFairDataFilterHelper.create();
