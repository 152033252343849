<template>
  <div class="page-wrapper container">
    <h1 class="page-title" data-aos="fade-up">Art Fairs</h1>
    <div class="page-desc" data-aos="fade-up">
      <span>{{ description }}</span>
    </div>

    <div class="app-data-filter-wrapper">
      <AppDataFilterOpenButton @click="handleOpenFilter" />
    </div>

    <MsgEntityCollectionEmpty v-if="!loadingArtFairs && !artFairs.length" name="Art Fairs" />

    <div class="content-wrp">
      <div class="row-considering-mobile">
        <EntityCard
          v-for="fair in artFairs"
          :key="fair.id"
          class="col-12 col-md-6 col-lg-4"
          :data="fair"
          data-aos="fade-up"
          type="art-fairs"
        />
      </div>
      <infinite-loading :identifier="infiniteLoaderId" @infinite="loadMoreArtFairs" />
    </div>

    <AppDataFilterMain
      v-model="filterValues"
      :filter-groups="filterGroups"
      :init-open="isAppDataFilterOpened"
      :is-mobile="isMobileScreen"
      @input="handleChangeFilterValues"
      @open="handleOpenFilter"
      @close="handleCloseFilter"
    />
  </div>
</template>

<script>
import { areRouteQueriesDifferent } from '@/helpers/routerHelper';
import { EntityTypes } from '@/helpers/entityType';
import ArtFairDataFilterHelper from '@/helpers/data-filter/ArtFairDataFilterHelper';

import pageMetadataQuery from '@/graphql/PageMetadata.query.gql';
import artFairsPageQuery from '@/graphql/artFair/ArtFairs.query.gql';
import artFairsPageFilterQuery from '@/graphql/artFair/ArtFairsPageFilter.query.gql';

import AppDataFilterMain from '@/components/data-filter/AppDataFilterMain';
import AppDataFilterOpenButton from '@/components/data-filter/AppDataFilterOpenButton';
import EntityCard from '@/components/partials/EntityCard';
import MsgEntityCollectionEmpty from '@/components/MsgEntityCollectionEmpty.vue';

const DATA_PER_PAGE = 9;

export default {
  name: 'ArtFairsPage',
  components: {
    MsgEntityCollectionEmpty,
    AppDataFilterOpenButton,
    AppDataFilterMain,
    EntityCard,
  },
  metaInfo: {
    title: 'Art Fairs',
  },
  data() {
    return {
      description: '',
      artFairs: [],
      loadingArtFairs: true,
      getMoreArtFairs: false,
      page: 1,
      infiniteLoaderId: +new Date(),
      filterGroups: [],
      filterValues: {},
      changingRouteAfterFilterUpdated: false,
    };
  },
  computed: {
    filterQueryVariables() {
      return ArtFairDataFilterHelper.prepareFilterValuesToQueryVariables(this.filterValues);
    },
    isAppDataFilterOpened() {
      return !!this.$store.state.isAppDataFilterOpened;
    },
    isMobileScreen() {
      return !!this.$store.state.isMobileScreen;
    },
  },
  created() {
    this.filterValues = ArtFairDataFilterHelper.getFilterValuesFromRouteQuery(this.$route.query);
    this.getFilterData();
  },
  beforeRouteUpdate(to, from, next) {
    if (!this.changingRouteAfterFilterUpdated) {
      this.filterValues = ArtFairDataFilterHelper.getFilterValuesFromRouteQuery(to.query);
    }
    this.resetFetchDataOptions();
    this.changingRouteAfterFilterUpdated = false;
    next();
  },
  apollo: {
    pageMetadata: {
      query: pageMetadataQuery,
      variables() {
        return {
          entityType: EntityTypes.art_fair,
        };
      },
      result(result) {
        this.description = result.data.pageMetadata?.description;
      },
    },
  },
  methods: {
    getFilterData() {
      return this.$apollo
        .query({
          query: artFairsPageFilterQuery,
          fetchPolicy: 'no-cache',
        })
        .then(({ data = {} }) => {
          this.filterGroups = ArtFairDataFilterHelper.prepareFilterGroups(data);
        });
    },
    handleOpenFilter() {
      this.$store.dispatch('toggleAppDataFilter', true);
    },
    handleCloseFilter() {
      this.$store.dispatch('toggleAppDataFilter', false);
    },
    handleChangeFilterValues() {
      const query = ArtFairDataFilterHelper.setFilterValuesToRouteQuery(this.filterValues);

      if (areRouteQueriesDifferent(this.$route.query, query)) {
        this.changingRouteAfterFilterUpdated = true;
        this.$router.push({ name: this.$route.name, params: this.$route.params, query });
      }
    },
    loadMoreArtFairs(loadingState) {
      this.loadingArtFairs = true;
      this.$apollo
        .query({
          query: artFairsPageQuery,
          fetchPolicy: 'no-cache',
          variables: {
            limit: DATA_PER_PAGE,
            page: this.page,
            ...this.filterQueryVariables,
          },
        })
        .then(({ data }) => {
          const { artFairs } = data;

          this.artFairs.push(...artFairs.data);
          this.getMoreArtFairs = artFairs.hasMorePages;
          this.loadingArtFairs = false;

          if (!this.getMoreArtFairs) {
            loadingState.complete();
            return;
          }
          this.page++;
          loadingState.loaded();
        })
        .catch(() => {
          this.loadingArtFairs = false;
          loadingState.complete();
        });
    },
    resetFetchDataOptions() {
      this.loadingArtFairs = true;
      this.page = 1;
      this.artFairs = [];
      this.infiniteLoaderId += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  margin-top: 100px;
  padding-bottom: 60px;

  @media (max-width: 767px) {
    .open {
      position: fixed;
      top: 0;
      padding-top: 20px;
      left: 0;
    }
  }
}
.page-desc {
  max-width: 550px;
}
.content-wrp {
  min-height: 300px;
}
</style>
